<template>
    <LMarker
        v-if="simpleMarkersEnabledConditionally"
        :draggable="isDraggable"
        :lat-lng="latLng"
        :visible="visible"
        @click="$emit('markerClicked', [asset])"
        @dragend="updateCoordinates"
    >
        <LTooltip :content="name" />
    </LMarker>

    <LMarker
        v-else
        :draggable="isDraggable"
        :icon="icon"
        :lat-lng="latLng"
        :options="{ tracker: asset.id, interactive }"
        :visible="visible"
        @click="$emit('markerClicked', [asset])"
        @dragend="updateCoordinates"
    >
        <LTooltip :content="name" />
    </LMarker>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { DivIcon, Icon } from 'leaflet'
import { LMarker, LTooltip } from 'vue2-leaflet'
import clsx from 'clsx'

import { formatHelper } from '@/utils'
import AssetHelpers from '@/mixins/AssetHelpers'

Icon.Default.imagePath = 'img/leaflet/'

const alertIcons = {
    'error-battery-low': require('../assets/icons/charging-battery-low-1.svg'),
    'error-generic': require('../assets/icons/outline-error.svg'),
    'error-outside-location': require('../assets/icons/cursor-move-target-right.svg'),
    'warning-generic': require('../assets/icons/fill-warning.svg'),
}

const createMarkerHeading = () => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('viewBox', '0 0 316 512')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute(
        'd',
        'M193.926 272.225a40 40 0 0 0 0-32.55L122.821 80.04c-19.559-38.207 20.949-60.18 45.7-40.957 45.93 35.67 162.301 143.023 214.993 192.01a33.935 33.935 0 0 1 0 49.713c-52.692 48.986-169.063 156.339-214.993 192.01-25.364 19.698-64.894-3.464-45.7-40.958z'
    )
    svg.appendChild(path)
    return svg
}

const createMarkerPin = () => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('viewBox', '0 0 424 512')
    svg.setAttribute('class', 'asset-marker__pin')
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute(
        'd',
        'M179.202 468.133a126.906 126.906 0 0 0-42.225-59.094l-48.178-37.398C39.53 333.401 9.601 275.34 7 212.955 7 99.764 98.781 8 211.999 8 325.219 8 417 99.764 417 212.955c-2.601 62.385-32.53 120.446-81.799 158.686l-48.178 37.398a126.906 126.906 0 0 0-42.225 59.094l-5.64 16.444c-3.984 11.621-14.895 19.425-27.159 19.423-12.265-.001-23.176-7.804-27.162-19.423l-5.635-16.444Z'
    )
    svg.appendChild(path)
    return svg
}

const AssetMarkerIcon = DivIcon.extend({
    createIcon() {
        const wrapper = document.createElement('div')
        this._setIconStyles(wrapper, 'icon')
        wrapper.style.background = 'none'
        wrapper.style.border = 'none'

        const marker = document.createElement('div')
        marker.className = clsx('asset-marker', {
            'asset-marker--selected': this.options.isSelected,
        })
        marker.style.color = this.options.color
        marker.appendChild(createMarkerPin())

        const img = document.createElement('img')
        img.src = this.options.iconUrl
        const imgWrapper = document.createElement('div')
        imgWrapper.className = 'asset-marker__icon'
        imgWrapper.appendChild(img)
        marker.appendChild(imgWrapper)

        if (this.options.errorIcon) {
            marker.appendChild(this.options.errorIcon)
        }

        if (typeof this.options.heading === 'number') {
            const heading = document.createElement('div')
            heading.className = 'asset-marker__heading'
            heading.style.transform = `rotate(${this.options.heading}deg)`
            heading.appendChild(createMarkerHeading())
            marker.appendChild(heading)
        }

        if (this.options.label) {
            marker.appendChild(this.options.label)
        }

        if (this.options.sensorData) {
            marker.appendChild(this.options.sensorData)
        }

        wrapper.appendChild(marker)
        return wrapper
    },
})

export default {
    name: 'AssetMarker',
    components: {
        LMarker,
        LTooltip,
    },
    mixins: [AssetHelpers],
    props: {
        asset: {
            type: Object,
            required: true,
        },
        iconUrl: {
            type: String,
            default: null,
        },
        informationPills: {
            type: Array,
            default: () => [],
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        isOn: {
            type: Boolean,
            default: undefined,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState('map', [
            'displayDriverName',
            'displayRemainingTime',
            'showLabels',
        ]),
        ...mapGetters('map', ['simpleMarkersEnabledConditionally']),
        driverLabel() {
            let label = ''
            if (
                this.displayDriverName &&
                this.asset.asset_details?.sensor_data.driver_name
            ) {
                label +=
                    '<br> 👤 ' +
                    this.asset.asset_details?.sensor_data.driver_name.value
            }
            if (
                this.displayRemainingTime &&
                this.asset.asset_details?.sensor_data
                    .driver_1_remaining_time_current_shift
            ) {
                const offset = 60 * 60
                label +=
                    '<br> ⏱ ' +
                    formatHelper.hoursAndMinutesDuration(
                        this.asset.asset_details?.sensor_data
                            .driver_1_remaining_time_current_shift.value -
                            offset
                    )
            }
            return label
        },
        isDraggable() {
            return (
                this.$route.name === 'editAsset' &&
                this.$route.params.id == this.asset.id &&
                this.asset.asset_details?.static
            )
        },
        icon() {
            return this.createIcon(
                this.iconUrl ||
                    this.asset.asset_details?.asset_type_marker ||
                    this.asset.asset_type?.marker,
                alertIcons[this.getError(this.asset)]
            )
        },
        latLng() {
            return this.asset.asset_details?.position
                ? [
                      this.asset.asset_details.position.latitude,
                      this.asset.asset_details.position.longitude,
                  ]
                : [this.asset.position?.lat, this.asset.position?.lng]
        },
        name() {
            return this.asset.asset_details?.name ?? this.asset.name
        },
    },
    methods: {
        ...mapMutations('tracker', ['updateAsset']),
        updateCoordinates(marker) {
            this.updateAsset({
                id: this.asset.asset_details?.id ?? this.asset.id,
                position: {
                    latitude: marker.target._latlng.lat,
                    longitude: marker.target._latlng.lng,
                },
            })
        },
        createIcon(iconUrl, errorIconUrl) {
            if (
                !this.asset?.asset_details?.asset_type &&
                !this.asset?.asset_type
            ) {
                console.error('Asset has no asset-type information', this.asset)
                return
            }

            let errorIcon
            if (errorIconUrl) {
                errorIcon = document.createElement('img')
                errorIcon.src = errorIconUrl
                errorIcon.className = 'error-icon'
            }

            let label
            if (this.showLabels) {
                label = document.createElement('span')
                label.className = 'icon-label'
                label.innerHTML = this.name + this.driverLabel
            }

            let sensorDataElements = []
            this.informationPills.forEach(pill => {
                if (pill.text) {
                    const pillElement = document.createElement('div')
                    pillElement.className = pill.className
                    pillElement.innerText = pill.text
                    sensorDataElements.push(pillElement)
                }
            })

            if (this.isOn !== undefined) {
                const element = document.createElement('div')
                element.className = clsx('sensor-data', 'sensor-data-running', {
                    'sensor-data-positive': this.isOn,
                    'sensor-data-indeterminate': this.isOn === null,
                    'sensor-data-negative': this.isOn === false,
                })
                sensorDataElements.push(element)
            }

            let sensorData
            if (sensorDataElements.length) {
                sensorData = document.createElement('div')
                sensorData.className = 'sensor-data-holder'
                sensorDataElements.forEach(data => {
                    sensorData.appendChild(data)
                })
            }

            const iconRatio = 424 / 512
            const iconWidth = 60 * iconRatio
            const iconHeight = 60
            const iconPointOffset = 4

            return new AssetMarkerIcon({
                color: this.asset.asset_details?.color || 'black',
                errorIcon,
                heading: this.asset.last_gps_measurement?.heading,
                iconAnchor: [iconWidth / 2, iconHeight - iconPointOffset],
                iconSize: [iconWidth, iconHeight],
                iconUrl,
                isSelected: this.isSelected,
                label,
                sensorData,
                tooltipAnchor: [
                    iconWidth / 2 + iconPointOffset,
                    -iconHeight / 2 / iconRatio + iconPointOffset,
                ],
            })
        },
    },
}
</script>

<style lang="scss">
$assetMarkerRatio: math.div(424, 512);
$assetMarkerOffset: math.div(64, 512);

.asset-marker {
    position: relative;

    &--selected {
        animation: markerBounce 1s infinite ease-in-out;
    }

    &__heading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100% * $assetMarkerRatio;
        z-index: 1;

        svg {
            position: absolute;
            top: -16px;
            left: 50%;
            width: 23px;
            height: 23px;
            stroke: #fff;
            stroke-width: 36px;
            fill: currentColor;
            transform: rotate(-90deg) translateY(-50%);
            filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.16));
        }
    }

    &__icon {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: (10% * ($assetMarkerRatio + $assetMarkerOffset)) 10%;
        width: 80%;
        height: 80% * $assetMarkerRatio;
        background-color: #fff;
        border-radius: 50%;

        img {
            padding: 20%;
            width: 100%;
            height: auto;
        }
    }

    &__pin {
        display: block;
        stroke: #fff;
        stroke-width: 16px;
        fill: currentColor;
        filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.16));
    }
}

@keyframes markerBounce {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(1%);
    }
    60% {
        transform: translateY(-4%);
    }
    100% {
        transform: translateY(0);
    }
}

.error-icon {
    position: absolute;
    padding: 2px;
    width: 18px;
    height: 18px;
    top: -2px;
    right: -2px;
    border-radius: 50%;
    background-color: #fff;
}

.icon-label {
    position: absolute;
    top: 50% * $assetMarkerRatio;
    left: 100%;
    margin-left: 8px;
    padding: 2px 4px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    transform: translateY(-50%);
}

.sensor-data-holder {
    position: absolute;
    top: -6px;
    left: -60px;
}
</style>
